import React from "react";
import tw from "twin.macro";

import BlueMediaStep from "@components/cases/bluemedia/step";

const Wrapper = tw.div`
  flex justify-between flex-wrap mx-30 mb-40
  sm:mx-20
  md:mx-0
  lg:mb-220 lg:ml-80
`;

const BlueMediaStepsLast = ({ width, scroll }: { width: number, scroll: any }) => (
  <Wrapper>
    <BlueMediaStep width={width} scroll={scroll} stepNumber={4}/>
    <BlueMediaStep width={width} scroll={scroll} stepNumber={5} wider/>
    <BlueMediaStep width={width} scroll={scroll} stepNumber={6}/>
    <BlueMediaStep width={width} scroll={scroll} stepNumber={7} className={'lg:mr-50'}/>
  </Wrapper>
);

export default BlueMediaStepsLast;
