import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type StepProps = {
  width: number,
  scroll: any,
  stepNumber: number,
  className?: string,
  wider?: boolean
};

const Wrapper = styled.div<{ wider: boolean }>`
  ${tw`flex mb-20 sm:w-290 sm:mb-50 md:w-370 lg:mb-70 lg:overflow-hidden`}
  
  ${({ wider }) => wider ? tw`lg:w-480` : tw`lg:w-430`}
`;

const Number = tw(motion.span)`
  min-w-20 text-sm text-portfolioText leading-24
  lg:min-w-40 lg:text-lg lg:leading-32 lg:-tracking-03
`;

const Step = styled(motion.span)`
  ${tw`
    text-second leading-24 -tracking-021
    lg:relative lg:text-md lg:leading-30 lg:-tracking-027
  `}
  
  br {
    ${tw`hidden sm:block`}
  }
`;

const BlueMediaStep = ({ width, scroll, stepNumber, className, wider = false }: StepProps) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 40, sectionStart + 400], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 40, sectionStart + 400], [100, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref} className={className} wider={wider}>
      <Number style={ width >= 1024 && { opacity }}>
        { stepNumber }.
      </Number>
      <Step style={ width >= 1024 && { left, opacity }}>
        {t(`Cases:BlueMedia:Steps:${stepNumber}1`)}
        <br/>
        {t(`Cases:BlueMedia:Steps:${stepNumber}2`)}
      </Step>
    </Wrapper>
  );
};

export default BlueMediaStep;
