import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import LayoutPortfolio from "@layouts/portfolio";
import SEO from "@components/seo";
import BlueMediaHeader from "@components/cases/bluemedia/header";
import BlueMediaStepsFirst from "@components/cases/bluemedia/steps-first";
import BlueMediaMockup from "@components/cases/bluemedia/mockup";
import BlueMediaStepsLast from "@components/cases/bluemedia/steps-last";
import BlueMediaBackground from "@components/cases/bluemedia/background"
import BlueMediaNaming from "@components/cases/bluemedia/naming";
import BlueMediaGoals from "@components/cases/bluemedia/goals";

const BlueMediaPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <LayoutPortfolio
      width={width}
      scroll={scrollY}
      name={'blue-media'}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Blue Media' })}
        description={intl.formatMessage({ id: 'SEO.Description.Blue Media' })}
      />
      <BlueMediaHeader width={width} scroll={scrollY} />
      <BlueMediaStepsFirst width={width} scroll={scrollY} />
      <BlueMediaMockup width={width} scroll={scrollY} />
      <BlueMediaStepsLast width={width} scroll={scrollY} />
      <BlueMediaBackground width={width} scroll={scrollY} />
      <BlueMediaNaming width={width} scroll={scrollY} />
      <BlueMediaGoals width={width} scroll={scrollY} />
    </LayoutPortfolio>
  );
};

export default BlueMediaPage;
