import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";
import ArrowRed from "@components/arrows/red";

const Wrapper = tw.div`
  relative mx-30 mb-40
  md:mx-0 md:mb-450
  lg:mb-550
  xl:mb-720
`;

const Text = styled(motion.div)`
  ${tw`
    mb-30 text-md leading-30 -tracking-024
    md:w-350 md:ml-auto
    lg:relative lg:w-470 lg:text-2xl lg:leading-51
    xl:lg:w-630
  `}
  
  span {
    ${tw`relative text-portfolioText`}
    
    &::after {
      content: '';
      ${tw`absolute bottom-1 right-0 w-full h-1 bg-portfolioText lg:h-2`}
    }
  }
`;

const Visuals = tw(motion.div)`
  w-full
  md:absolute md:top-20 md:w-540
  lg:top-120 lg:w-720
  xl:top-60 xl:w-850
`;

const ArrowDefault = styled.div`
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const ArrowWrapper = tw(ArrowDefault)`
  hidden
  md:block md:absolute md:right-5 md:w-90
  lg:w-160
  xl:right-60
`;

const ArrowRedWrapper = tw(ArrowDefault)`
  hidden
  md:block md:absolute md:top-440 md:left-140 md:w-115
  lg:top-600 lg:w-200
  xl:top-710 xl:left-230
`;

const BlueMediaNaming = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      visuals: file(relativePath: { eq: "cases/bluemedia-visuals.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [0, 1]);
  const opacityDelay = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Text style={ width >= 1024 && { y, rotate, opacity }}
      >
        {t('Cases:BlueMedia:Naming')}
      </Text>
      <Visuals style={ width >= 1024 && { opacity: opacityDelay }}>
        <Img fluid={data.visuals.childImageSharp.fluid} />
      </Visuals>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <ArrowRedWrapper>
        <ArrowRed />
      </ArrowRedWrapper>
    </Wrapper>
  );
};

export default BlueMediaNaming;
