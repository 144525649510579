import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import Img from "gatsby-image";

import BlueMediaStep from "@components/cases/bluemedia/step";

const Wrapper = tw.div`relative mx-30 lg:mx-80`;

const Heading = tw(motion.div)`
  w-220 mt-50 mb-260 text-second leading-24 -tracking-024
  sm:w-250 sm:mt-80 sm:mb-80
  lg:relative lg:w-500 lg:mt-50 lg:mb-130 lg:text-2xl lg:text-primary lg:leading-51
`;

const Illustration = tw(motion.div)`
  absolute! top-30 left-20 w-300
  sm:-top-40 sm:left-280
  md:left-380
  lg:-top-50 lg:left-auto lg:-right-110 lg:w-470
`;

const BlueMediaStepsFirst = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart, sectionStart + 400], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart, sectionStart + 400], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart, sectionStart + 400], [0, 1]);
  const top = useTransform(scroll, [sectionStart, sectionStart + 400], [80, -50]);
  const right = useTransform(scroll, [sectionStart, sectionStart + 400], [20, -110]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Heading style={ width >= 1024 && { y, rotate, opacity }}>
        {t('Cases:BlueMedia:Cooperation')}
      </Heading>
      <Illustration style={ width >= 1024 && { top, right, opacity }}>
        <Img fluid={data.illustration.childImageSharp.fluid} />
      </Illustration>
      <BlueMediaStep
        width={width}
        scroll={scroll}
        stepNumber={1}
        className={'lg:pb-70'}
      />
      <BlueMediaStep
        width={width}
        scroll={scroll}
        stepNumber={2}
        className={'sm:ml-100 lg:ml-280 lg:pb-50'}
      />
      <BlueMediaStep
        width={width}
        scroll={scroll}
        stepNumber={3}
        className={'sm:ml-300 md:ml-380 lg:ml-auto'}
      />
    </Wrapper>
  );
};

export default BlueMediaStepsFirst;
