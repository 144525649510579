import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import Img from "gatsby-image";

const Background = tw(fullWidthBox)`
  h-280 mt-60 mb-100 bg-oval
  md:h-385 md:mb-140
  lg:h-612 lg:mb-292
`;

const Mockup = tw(motion.div)`
  relative -top-45 left-150 w-650
  sm:left-330
  md:-top-70 md:left-190 md:w-1000
  lg:-top-111 lg:left-1/2 lg:w-1600 lg:transform lg:-translate-x-1/2
`;

const BlueMediaBackground = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      mockup: file(relativePath: { eq: "cases/bluemedia-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 200, sectionStart + 550], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Background ref={Ref}>
      <Mockup style={ width >= 1024 && { opacity }}>
        <Img fluid={data.mockup.childImageSharp.fluid} />
      </Mockup>
    </Background>
  );
};

export default BlueMediaBackground;
