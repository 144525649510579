import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowSecondary from "@components/arrows/secondary";

const Wrapper = tw.div`
  relative mt-26 mb-50
  sm:flex sm:flex-wrap sm:items-center sm:mt-30
  lg:items-start lg:mt-280 lg:mb-120
`;

const Logo = tw(motion.div)`
  w-155 mb-26 ml-40
  sm:ml-auto sm:mr-40
  lg:w-218 lg:mt-20 lg:mr-0
  xl:mr-110
`;

const Text = tw(motion.div)`
  mb-30 ml-40 mr-30 text-sm text-second leading-18 -tracking-021
  sm:order-first sm:w-300
  lg:relative lg:w-700 lg:ml-0 lg:text-primary lg:text-2xl lg:leading-52 lg:-tracking-024
  xl:ml-110
`;

const Video = tw(motion.div)`
  flex justify-center items-center h-200 mx-20
  sm:w-380 sm:mt-20 sm:ml-auto sm:mr-40
  lg:h-330 lg:w-630 lg:mt-70 lg:mr-0
  xl:mr-110
`;

const Iframe = tw.iframe`w-full h-full`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden transform -scale-x-100
    sm:block sm:absolute sm:top-180 sm:left-50 sm:w-80
    lg:top-auto lg:left-160 lg:-bottom-80 lg:w-140 lg:-rotate-60
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const BlueMediaHeader = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "cases/bluemedia.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const scale = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const rotate = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [-8, 0]);
  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [-140, 0]);
  const opacityDelay = useTransform(scroll, [sectionStart + 400, sectionStart + 680], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 400, sectionStart + 680], [80, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Logo style={ width >= 1024 && { scale, rotate }}>
        <Img fluid={data.logo.childImageSharp.fluid} />
      </Logo>
      <Text style={ width >= 1024 && { opacity, left }}>
        {t('Cases:BlueMedia:More info')}
      </Text>
      <Video style={ width >= 1024 && { opacity: opacityDelay, x }}>
        <Iframe
          src="https://www.youtube.com/embed/fhBrkWmsr7s"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Video>
      <ArrowWrapper>
        <ArrowSecondary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default BlueMediaHeader;
