import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`
  relative flex flex-wrap mx-30 mb-80
  md:mb-340
  lg:mb-250
  xl:mb-60
`;

const Text = styled(motion.div)`
  ${tw`
    w-full mt-30 text-second leading-25 -tracking-024
    sm:w-360
    md:w-5/12
    lg:relative lg:mt-55 lg:w-540 lg:text-md lg:-tracking-027
    xl:pl-80
  `}
  
  br {
    ${tw`hidden sm:block`}
  }
  
  b {
    ${tw`font-pangramBold`}
  }
`;

const MockupWrapper = tw(motion.div)`
  hidden
  md:block md:relative md:w-7/12
  lg:w-auto lg:h-700 lg:mt-55
  xl:h-900
`;

const Mockup = tw(Img)`
  absolute!
  md:left-50 md:w-480
  lg:w-700
  xl:-top-20 xl:left-70 xl:w-810
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100
  md:block md:absolute md:-bottom-240 md:left-50 md:w-180
  lg:bottom-80 lg:left-80 lg:w-240
  xl:bottom-240 xl:w-330
`;

const BlueMediaGoals = ({ width, scroll }: { width: number, scroll: any }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      mockup: file(relativePath: { eq: "cases/bluemedia-mockup2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [0, 1]);
  const opacityDelay = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 80, sectionStart + 480], [-120, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Text style={ width >= 1024 && { opacity, left }}>
        {t('Cases:BlueMedia:Business goals1')}
        <b>{t('Cases:BlueMedia:Business goals2')}</b>
        {t('Cases:BlueMedia:Business goals3')}
      </Text>
      <MockupWrapper style={ width >= 1024 && { opacity: opacityDelay }}>
        <Mockup fluid={data.mockup.childImageSharp.fluid} />
      </MockupWrapper>
      <DetailsWrapper>
        <DetailsLarge />
      </DetailsWrapper>
    </Wrapper>
  );
};

export default BlueMediaGoals;
