import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";
import ArrowPrimary from "@components/arrows/primary"
import ArrowSecondary from "@components/arrows/secondary"

const Wrapper = tw.div`
  relative mt-30 mx-20 mb-100
  sm:-mt-115 sm:mb-180
  md:-mt-90 md:mb-240
  lg:-mt-125 lg:mb-380
  xl:mb-520
`;

const Team = tw(motion.div)`
  relative h-240
  sm:w-280
  md:w-340 md:h-240
  lg:w-450 lg:h-350
  xl:w-630 xl:h-470
`;

const Illustration = tw(motion.div)`
  absolute -bottom-80 -right-50 w-180
  sm:-bottom-150 sm:left-200 sm:right-0 sm:w-240
  md:-bottom-180 md:left-240 md:w-300
  lg:-bottom-280 lg:w-450
  xl:-bottom-365 xl:left-460 xl:w-558
`;

const ArrowDefault = styled.div`
  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const ArrowWrapper = tw(ArrowDefault)`
  hidden transform -rotate-180
  sm:block sm:absolute sm:-bottom-160 sm:left-120 sm:w-70
  lg:-bottom-300 lg:left-70 lg:w-160
  xl:-bottom-340 xl:left-230
`;

const ArrowSecondWrapper = tw(ArrowDefault)`
  hidden transform rotate-110 -scale-y-100
  sm:block sm:absolute sm:bottom-20 sm:left-450 sm:w-70
  md:bottom-50 md:left-550
  lg:bottom-60 lg:left-700 lg:w-120
  xl:left-1050
`;

const BlueMediaMockup = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      team: file(relativePath: { eq: "cases/bluemedia-team.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      illustration: file(relativePath: { eq: "illustration-orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 80, sectionStart + 450], [-120, 0]);
  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 450], [0, 1]);
  const opacityDelay = useTransform(scroll, [sectionStart + 400, sectionStart + 700], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Team style={ width >= 1024 && { left, opacity }}>
        <Img fluid={data.team.childImageSharp.fluid} />
      </Team>
      <Illustration style={ width >= 1024 && { opacity: opacityDelay }}>
        <Img fluid={data.illustration.childImageSharp.fluid} />
      </Illustration>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <ArrowSecondWrapper>
        <ArrowSecondary />
      </ArrowSecondWrapper>
    </Wrapper>
  );
};

export default BlueMediaMockup;
